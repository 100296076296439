/* Fixed card dimensions */
.fixed-card {
  width: 350px;
  height: 530px;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

/* Hover effect for card */
.fixed-card:hover {
  transform: translateY(-5px);
}

/* Date header styling */
.card-date {
  background-color: #f8f9fa;
  color: #343a40;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #dee2e6;
}

/* Fixed image styling */
.fixed-card-img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/* Card body taking remaining space */
.fixed-card-body {
  height: calc(100% - 200px);
  padding: 20px;
  justify-content: space-between;
}

/* Styling for details text */
.card-details p {
  margin: 0.3rem 0;
  font-size: 0.95rem;
  color: #555;
}

/* Hero Section Styles */
.slider-area2 {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero__caption h1 {
  font-size: 3rem;
  color: #fff;
  margin-bottom: 10px;
}

.hero__caption p {
  color: #fff;
  font-size: 1.25rem;
}
